import React, { useState } from 'react';
import axiosInstance from '../../../utils/axiosInstance';
import { validatePassword } from '../../../utils/validators';
import BackButton from '../../BackButton/BackButton';
import Button from '../../Button/Button';
import Input from '../../Input/Input';
import style from './NewPassword.module.css';

interface NewPasswordFormProps {
  email: string;
  otp: string;
  setActiveComponent: (component: string) => void;
  onPasswordChanged: (password: string) => void;
}

const NewPasswordForm: React.FC<NewPasswordFormProps> = ({
  email,
  otp,
  setActiveComponent,
  onPasswordChanged,
}) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validationState, setValidationState] = useState<'valid' | 'invalid'>(
    'invalid',
  );
  const [error, setError] = useState<string>('');

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setValidationState(validatePassword(newPassword) ? 'valid' : 'invalid');
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    if (!validatePassword(password)) {
      setError(
        'Password must be at least 8 characters long and include at least one letter and one number or special character.',
      );
      return;
    }

    try {
      const response = await axiosInstance.post('/Auth/ResetPassword', {
        email,
        password,
        otp,
      });

      if (response.status === 200) {
        onPasswordChanged(password);
      }
    } catch (error) {
      setError('Ошибка при сбросе пароля. Попробуйте снова.');
    }
  };

  const isFormValid =
    password &&
    confirmPassword &&
    password === confirmPassword &&
    validationState === 'valid';

  return (
    <div className={style.new_pass_container}>
      <BackButton onClick={() => setActiveComponent('login')} />
      <h2 className={style.title_new_pass}>Створення нового паролю</h2>
      <p className={style.text_offer}>
        Будь ласка, створіть новий пароль для входу
        <br /> до вашого облікового запису.
      </p>
      <form onSubmit={handleSubmit} className={style.new_pass_form}>
        <Input
          id="password"
          labelText="Новий Пароль"
          type="password"
          value={password}
          onChange={handlePasswordChange}
          required={true}
        />
        <Input
          id="confirmPassword"
          labelText="Повторіть Пароль"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required={true}
        />
        {error && <p>{error}</p>}
        <p className={style.text_rules}>
          Пароль повинен містити щонайменше 8 символів.
          <br /> Включати одну літеру та одну цифру або символ.
        </p>
        <Button disabled={!isFormValid} type="submit" text={'Створити'} />
      </form>
    </div>
  );
};

export default NewPasswordForm;
