import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { Company as CompanyType } from '../../types';
import ConfirmModal from '../ConfirmationModal/ConfirmationModal';
import ControlPanel from '../ControlPanel/ControlPanel';
import CustomerListHead from '../Customer/CustomerList/CustomerListHead/CustomerListHead';
import style from './Company.module.css';
import {
  getPlanClass,
  handleConfirmDelete,
  handleSortArrowClick,
  loadCompanies,
  resendCompanyPasswordAndQr,
} from './companyHelpers';
import DefaultAvatar from './icons/defaultAvatar';
import MoreVert from './icons/MoreVert';
import ModalWindowCompany from './ModalWindowCompany/ModalWindowCompany';
import ModalWindowEmployees from './ModalWindowEmployees/ModalWindowEmployees';
import SuccessModal from './SuccessModal/SuccessModal';
import CompanyForm from './СompanyForm/CompanyForm';

const Company: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { companies, searchResults, loading, orderDirection, hasMore } =
    useSelector((state: RootState) => state.companies);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isRotated, setIsRotated] = useState<boolean>(false);
  const [selectedClientId, setSelectedClientId] = useState<number | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showCompanyForm, setShowCompanyForm] = useState<boolean>(false);
  const [showEmployeesModal, setShowEmployeesModal] = useState<boolean>(false);
  const [editCompanyData, setEditCompanyData] = useState<CompanyType | null>(
    null,
  );
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [successEmail, setSuccessEmail] = useState<string | null>(null);
  const companyRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const pageIndexRef = useRef(0);
  const orderDirectionRef = useRef(orderDirection);
  const isInitialMount = useRef(true);
  const [buttonRef, setButtonRef] = useState<{
    current: HTMLButtonElement | null;
  } | null>(null);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      loadCompanies(dispatch, pageIndexRef, orderDirectionRef);
    }
  }, [dispatch]);

  const handleSearch = (query: string) => {
    if (query.trim()) {
      setIsSearching(true);
    } else {
      setIsSearching(false);
      pageIndexRef.current = 0;
      loadCompanies(dispatch, pageIndexRef, orderDirectionRef);
    }
  };

  const handleSortingDirectionToggle = useCallback(() => {
    setIsRotated((prev) => !prev);
    pageIndexRef.current = 0;
    orderDirectionRef.current = isRotated ? 'ASC' : 'DESC';
    loadCompanies(dispatch, pageIndexRef, orderDirectionRef);
  }, [dispatch, isRotated]);

  const companyListClass =
    companies.length <= 8 ||
    (isSearching && !loading && searchResults.length <= 8)
      ? style.wrapperCompanyExtra
      : style.wrapperCompany;

  const companiesToDisplay =
    searchResults.length > 0 && isSearching ? searchResults : companies;

  const observer = useRef<IntersectionObserver | null>(null);

  const lastCompanyRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (loading || !hasMore || isSearching) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            pageIndexRef.current += 1;
            loadCompanies(dispatch, pageIndexRef, orderDirectionRef);
          }
        },
        {
          rootMargin: '100px',
          threshold: 0.1,
        },
      );
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, isSearching],
  );

  const handleClientMenuClick = (companyId: number, index: number) => {
    setSelectedClientId(companyId);
    setButtonRef({ current: companyRefs.current[index] });
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedClientId(null);
  };

  const handleDetails = (id: number) => {
    const company = companies.find((company) => company.id === id) || null;
    setEditCompanyData(company);
    setShowCompanyForm(true);
    handleCloseModal();
  };

  const handleDelete = () => {
    setShowConfirmModal(true);
    setIsModalOpen(false);
  };

  const handleSendInvitation = async (id: number) => {
    const company = companies.find((company) => company.id === id);
    if (!company) return;

    try {
      await resendCompanyPasswordAndQr(id);
      setSuccessEmail(company.email);
      setShowSuccessModal(true);
    } catch (error) {
      console.error('Error sending invitation:', error);
    } finally {
      handleCloseModal();
    }
  };

  const handleCancelDelete = () => {
    setShowConfirmModal(false);
  };

  const handleSaveCompany = () => {
    setShowCompanyForm(false);
    loadCompanies(dispatch, pageIndexRef, orderDirectionRef);
  };

  const handleAddCompany = () => {
    setEditCompanyData(null);
    setShowCompanyForm(true);
  };

  const handleEmployeesModalOpen = (companyId: number | null) => {
    setSelectedClientId(companyId);
    setShowEmployeesModal(true);
    setIsModalOpen(false);
  };

  const selectedClientName =
    companies.find((company) => company.id === selectedClientId)?.userName ||
    'Unknown';

  const openEmployeesModal = (companyId: number) => {
    setSelectedClientId(companyId);
    setShowEmployeesModal(true);
  };

  return (
    <div className={style.company}>
      <ControlPanel
        setIsSearching={setIsSearching}
        onSearch={handleSearch}
        onAddCompany={handleAddCompany}
      />
      <div
        className={`${companyListClass} ${isSearching && companiesToDisplay.length <= 8 ? style.wrapperCompanyExtra : ''}`}
      >
        <CustomerListHead
          sortingLogo={orderDirection === 'asc'}
          isRotated={isRotated}
          toggleSortingDirection={handleSortingDirectionToggle}
          handleSortArrowClick={() => handleSortArrowClick(setIsRotated)}
        />
        <div className={style.companyList}>
          {isSearching && !loading && searchResults.length === 0 ? (
            <p className={style.noResults}>
              Компанію не знайдено. Спробуйте іншу назву або додайте нову
              компанію, щоб розпочати роботу.
            </p>
          ) : (
            companiesToDisplay.map((company: CompanyType, index: number) => (
              <div
                key={`${company.id}-${index}`}
                className={`${style.companyItem} ${
                  index === companiesToDisplay.length - 1
                    ? style.lastCompanyItem
                    : ''
                }`}
                ref={
                  index === companiesToDisplay.length - 1
                    ? lastCompanyRef
                    : null
                }
              >
                <p>{index + 1}</p>
                <div className={style.inotify}>
                  <div className={style.wrapAvatar}>
                    {company.avatarUrl ? (
                      <img
                        src={company.avatarUrl}
                        alt={`${company.userName}'s avatar`}
                        className={style.avatar}
                      />
                    ) : (
                      <DefaultAvatar />
                    )}
                  </div>
                  <p>
                    {company.name.length > 80
                      ? `${company.name.slice(0, 80)} . . .`
                      : company.name}
                  </p>
                </div>
                <p>{company.edrpou}</p>
                <div className={getPlanClass(company.plan)}>{company.plan}</div>
                <p>
                  {company.contractFinishAt
                    ? `до ${new Date(
                        company.contractFinishAt,
                      ).toLocaleDateString('uk-UA', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                      })}`
                    : 'Безстроковий'}
                </p>
                <div>{company.phone}</div>
                <button
                  ref={(el) => (companyRefs.current[index] = el)}
                  className={style.clientMenu}
                  onClick={() => handleClientMenuClick(company.id, index)}
                >
                  <MoreVert />
                </button>
              </div>
            ))
          )}
        </div>
        {isModalOpen && (
          <ModalWindowCompany
            company={companies}
            selectedClientId={selectedClientId}
            onClose={handleCloseModal}
            onDetails={handleDetails}
            onSendInvitation={handleSendInvitation}
            onDelete={handleDelete}
            buttonRef={buttonRef!}
            onShowEmployees={() => handleEmployeesModalOpen(selectedClientId)}
          />
        )}
        {showConfirmModal && selectedClientId !== null && (
          <ConfirmModal
            userName={
              companies.find((company) => company.id === selectedClientId)?.name
            }
            avatarUrl={
              companies.find((company) => company.id === selectedClientId)
                ?.avatarUrl
            }
            onConfirm={() =>
              handleConfirmDelete(
                selectedClientId,
                () => loadCompanies(dispatch, pageIndexRef, orderDirectionRef),
                setShowConfirmModal,
              )
            }
            onCancel={handleCancelDelete}
            type={'Company'}
          />
        )}
        {showCompanyForm && (
          <CompanyForm
            companyData={editCompanyData || undefined}
            onClose={() => setShowCompanyForm(false)}
            onSave={handleSaveCompany}
            isEdit={!!editCompanyData}
            onOpenEmployeesModal={openEmployeesModal}
          />
        )}
        {showEmployeesModal && (
          <ModalWindowEmployees
            selectedClientId={selectedClientId}
            onClose={() => setShowEmployeesModal(false)}
            onOpen={() => handleEmployeesModalOpen(selectedClientId)}
            selectedClientName={selectedClientName}
          />
        )}
        {showSuccessModal && (
          <SuccessModal
            email={successEmail}
            onClose={() => setShowSuccessModal(false)}
          />
        )}
      </div>
    </div>
  );
};

export default Company;
