import { useEffect, useState } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../../store/reducers/snackbarSlice';
import { Company, Tag } from '../../../../types';
import axiosInstance from '../../../../utils/axiosInstance';

interface WorkTime {
  timeStart: string;
  timeEnd: string;
  dayOfTheWeek: number;
}

export const useCompanyForm = (
  source: 'companyData' | 'formDataArray',
  companyData?: Company,
  formDataArray?: Company[],
  isEdit = false,
  requestPlanId?: any,
  onUpdate?: () => void,
  isPlanComponent: boolean = false,
) => {
  const dispatch = useDispatch();

  const initialCompanyState: Company = {
    id: 0,
    userName: '',
    name: '',
    avatarUrl: '',
    plan: '',
    planId: 0,
    phone: '',
    email: '',
    workTime: [],
    edrpou: '',
    directorUserName: '',
    directorPosition: '',
    iban: '',
    website: '',
    contractNumber: '',
    contractStartAt: '',
    contractFinishAt: '',
    tags: [],
    newPlan: '',
    newPlanId: 0,
  };
  const [selectedFormIndex, setSelectedFormIndex] = useState<number>(0);
  const [formData, setFormData] = useState<Company[]>(formDataArray || []);
  const [formattedWorkTime, setFormattedWorkTime] = useState<
    string | undefined
  >('');

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, touchedFields },
    reset,
    setValue,
    getValues,
    watch,
    trigger,
  } = useForm<Company>({
    defaultValues: initialCompanyState,
    mode: isPlanComponent ? 'onChange' : 'onBlur',
  });

  const formatWorkTime = (workTime: WorkTime[]): string => {
    if (!workTime || workTime.length === 0) return '';

    const daysOfWeekShort = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Нд'];

    return workTime
      .map((wt) => {
        const dayIndex = wt.dayOfTheWeek - 1;
        return `${daysOfWeekShort[dayIndex]} ${wt.timeStart} - ${wt.timeEnd}`;
      })
      .join(', ');
  };

  const [isDateModalOpen, setDateModalOpen] = useState(false);
  const [isWorkTimeModalOpen, setWorkTimeModalOpen] = useState(false);
  const [workTime, setWorkTime] = useState<WorkTime[]>([]);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);

  useEffect(() => {
    if (source === 'companyData' && companyData) {
      const parsedWorkTime = companyData.workTime || [];
      setWorkTime(parsedWorkTime);
      setSelectedTags(companyData.tags || []);
      setFormattedWorkTime(formatWorkTime(parsedWorkTime));

      const planIdToSet = companyData.newPlanId || companyData.planId || '';
      setValue('plan', planIdToSet.toString());
      reset({ ...companyData, plan: planIdToSet.toString() });
      trigger();
    } else if (source === 'formDataArray' && formDataArray?.length) {
      const currentData = formDataArray[selectedFormIndex];

      const parsedWorkTime = currentData.workTime || [];
      setWorkTime(parsedWorkTime);
      setSelectedTags(currentData.tags || []);
      setFormattedWorkTime(formatWorkTime(parsedWorkTime));

      const planIdToSet = currentData.newPlanId || currentData.planId || '';
      setValue('plan', planIdToSet.toString());

      reset({
        ...currentData,
        plan: planIdToSet.toString(),
      });
      trigger();
    } else {
      reset(initialCompanyState);
      trigger();
    }
  }, [
    companyData,
    formDataArray,
    source,
    reset,
    selectedFormIndex,
    setValue,
    trigger,
  ]);

  useEffect(() => {
    setValue('workTime', workTime);
    setValue('tags', selectedTags);
  }, [workTime, selectedTags, setValue]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      trigger(name);
    });

    return () => subscription.unsubscribe();
  }, [watch, trigger]);

  const openDateModal = () => setDateModalOpen(true);
  const closeDateModal = () => setDateModalOpen(false);

  const openWorkTimeModal = () => setWorkTimeModalOpen(true);
  const closeWorkTimeModal = () => setWorkTimeModalOpen(false);

  const handleDateSelect = (dates: {
    startDate: Date | null;
    endDate: Date | null;
  }) => {
    if (dates.startDate) {
      setValue('contractStartAt', new Date(dates.startDate).toISOString());
    }
    if (dates.endDate) {
      setValue('contractFinishAt', new Date(dates.endDate).toISOString());
    }
  };

  const handleWorkTimeSave = (workTime: WorkTime[]) => {
    setWorkTime(workTime);
    setFormattedWorkTime(formatWorkTime(workTime));
  };

  const handleTagChange = (tags: Tag[]) => {
    setSelectedTags(tags);
  };

  const formatTimeWithSeconds = (time: string): string => {
    const [hours, minutes = '00', seconds = '00'] = time.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`;
  };

  const transformDataToContract = (data: Company) => ({
    userData: {
      userName: data.userName,
      email: data.email,
      phone: data.phone,
      avatarUrl: data.avatarUrl || '',
      id: data.id ? data.id.toString() : '',
    },
    workTime: data.workTime.map((time: WorkTime) => ({
      timeStart: formatTimeWithSeconds(time.timeStart),
      timeEnd: formatTimeWithSeconds(time.timeEnd),
      dayOfTheWeek: time.dayOfTheWeek,
    })),
    name: data.name,
    edrpou: data.edrpou,
    directorUserName: data.directorUserName,
    directorPosition: data.directorPosition,
    iban: data.iban,
    website: data.website,
    contractNumber: data.contractNumber,
    tagIds: data.tags.map((tag) => tag.id),
  });

  const updateClientPlan = async (
    clientId: string,
    planId: number,
    contractNumber: string,
    startAt: string | null,
    finishAt: string | null,
  ) => {
    try {
      await axiosInstance.patch('/Admin/UpdateClientPlan', {
        clientId,
        planId,
        contractNumber,
        contractStartAt: startAt,
        contractFinishAt: finishAt,
      });
    } catch (error) {
      console.error('Помилка при оновленні плану:', error);
      dispatch(
        showSnackbar({
          message: 'Сталася помилка при оновленні плану',
          type: 'error',
        }),
      );
      throw error;
    }
  };

  const onSubmit = async (data: Company) => {
    const transformedData = transformDataToContract(data);

    try {
      let responseData;

      if (isEdit) {
        responseData = await axiosInstance.patch(
          '/Admin/UpdateCompany',
          transformedData,
        );

        if (responseData.status === 200) {
          dispatch(
            showSnackbar({
              message: 'Дані компанії успішно відредаговані',
              type: 'success',
            }),
          );
        }
      } else {
        responseData = await axiosInstance.put(
          '/Admin/AddCompany',
          transformedData,
        );

        if (responseData.status === 200) {
          dispatch(
            showSnackbar({
              message: 'Компанія успішно створена',
              type: 'success',
            }),
          );
        }
      }

      const values = getValues();
      const startAt = values.contractStartAt;
      const finishAt = values.contractFinishAt;
      const planId = values.plan;
      const contractNumber = values.contractNumber;

      if (startAt && finishAt && planId && contractNumber) {
        await updateClientPlan(
          responseData.data.id,
          Number(planId),
          contractNumber,
          startAt,
          finishAt,
        );
      }

      if (responseData.status === 200 && requestPlanId) {
        try {
          await axiosInstance.delete('/Admin/RemovePlanRequest', {
            params: {
              requestPlanId: requestPlanId,
            },
          });

          if (onUpdate) {
            onUpdate();
          }
        } catch (error) {
          console.error('Помилка при видаленні плану:', error);
          dispatch(
            showSnackbar({
              message: 'Сталася помилка при видаленні плану',
              type: 'error',
            }),
          );
        }
      }

      return responseData.data;
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.Message || error.response?.data?.message;
      console.log(errorMessage);
      if (errorMessage === 'User already exists') {
        console.log(123);
        dispatch(
          showSnackbar({
            message: 'Компанія з таким email вже існує',
            type: 'error',
          }),
        );
      } else {
        dispatch(
          showSnackbar({
            message: 'Сталася помилка при збереженні компанії',
            type: 'error',
          }),
        );
      }

      throw error;
    }
  };

  return {
    control,
    handleSubmit,
    errors: errors as FieldErrors<Company>,
    isValid,
    reset,
    openDateModal,
    closeDateModal,
    isDateModalOpen,
    handleDateSelect,
    openWorkTimeModal,
    closeWorkTimeModal,
    isWorkTimeModalOpen,
    handleWorkTimeSave,
    onSubmit,
    watch,
    selectedTags,
    handleTagChange,
    setValue,
    workTime,
    formattedWorkTime,
    setSelectedFormIndex,
    formData,
    trigger,
    touchedFields,
  };
};
