import axiosInstance from '../../utils/axiosInstance';
import {
  appendCompaniesSuccess,
  loadCompaniesFailure,
  loadCompaniesRequest,
  loadCompaniesSuccess,
  searchCompaniesFailure,
  searchCompaniesRequest,
  searchCompaniesSuccess,
} from '../actions/companyActions';
import { AppDispatch, RootState } from '../store';
import { Company, FetchCompaniesResponse } from '../type/companyType';

export const fetchCompanies =
  (
    pageIndex: number,
    pageSize: number,
    orderDirection: string = 'ASC',
    searchString: string = '',
  ) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
  ): Promise<FetchCompaniesResponse> => {
    dispatch(loadCompaniesRequest({ pageIndex, pageSize, orderDirection }));
    try {
      const response = await axiosInstance.post('/Admin/GetCompanyList', {
        pageIndex,
        pageSize,
        searchString,
        orderDirection,
      });

      const companies = response.data;
      const hasMore = companies.items.length === pageSize;

      const existingCompanies: Company[] = getState().companies.companies;

      const uniqueCompanies = companies.items.filter(
        (newCompany: Company) =>
          !existingCompanies.some(
            (existing: Company) => existing.id === newCompany.id,
          ),
      );

      if (pageIndex === 0) {
        dispatch(loadCompaniesSuccess({ items: uniqueCompanies, hasMore }));
      } else {
        dispatch(appendCompaniesSuccess({ items: uniqueCompanies, hasMore }));
      }
      return companies;
    } catch (error: any) {
      dispatch(loadCompaniesFailure(error.message));
      throw error;
    }
  };

export const searchCompanies =
  (query: string) => async (dispatch: AppDispatch) => {
    dispatch(searchCompaniesRequest());
    try {
      const response = await axiosInstance.post('/Admin/GetCompanyList', {
        pageIndex: 0,
        pageSize: 10,
        searchString: query,
        orderDirection: 'ASC',
      });
      const searchResults = response.data.items;
      dispatch(searchCompaniesSuccess(searchResults));
    } catch (error: any) {
      dispatch(searchCompaniesFailure(error.message));
    }
  };
